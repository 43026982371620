import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../Redux/Store';
import { userManagementActions } from '../reducer/userManagementSlice';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DesktopTable from '../../../../../../components/common/DesktopTable';
import {
    useGetDataForFilterDropdowns,
    useGetUsers,
} from '../hooks/userManagementHooks';
import UserModal from './UserModal';
import { useModalStatus } from '../../../../../../commonHooks/useModalStatus';
import { useNavigate } from 'react-router-dom';
import Constants from '../../../../../../utils/Constants';
import { commonFeatures } from '../../../../../common/featureFlags/commonFeatures';
import { UserManagementFilter } from './UserManagementFilter';
import { ILocalFilter } from '../interfaces/UserManagementInterface';
import { feedlotManagerFeatures } from '../../../../featureFlags/feedlotManagerFeatures';

const { isCA13354On_RestrictLegacyUsersOnUserManagement } = commonFeatures;

const { isCA13360On_FixUserManagementTableHeading } = feedlotManagerFeatures;

const UserManagement = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation('userManagement');
    const userManagementFilter = useAppSelector(
        state => state.userManagement.userManagementFilter,
    );

    const [localFilter, setLocalFilter] = useState<ILocalFilter>({
        name: userManagementFilter.name,
        email: userManagementFilter.email,
    });

    const { myAppsForModalDropdown, myAccounts } =
        useGetDataForFilterDropdowns();

    const { users, isFetching } = useGetUsers({
        appDropdownOptionsForModal: myAppsForModalDropdown,
        accessibleAccounts: myAccounts,
    });

    const handleScroll = () => {
        const canCallApi =
            users.length ===
            userManagementFilter.limit + userManagementFilter.offset;
        if (!isFetching && canCallApi) {
            const filterCopy = {
                ...userManagementFilter,
                ...localFilter,
                offset:
                    userManagementFilter.limit + userManagementFilter.offset,
            };
            dispatch(userManagementActions.setUserManagementFilter(filterCopy));
        }
        return;
    };

    const [modalId, setModalId] = useState(1);
    const createEditUserModal = useModalStatus();
    const activateEditUserModal = () => {
        createEditUserModal.open();
    };
    const onHide = () => {
        setModalId(modalId + 1);
        createEditUserModal.close();
        setEditUserId(0);
    };

    const [editUserId, setEditUserId] = useState(0);

    const editUser = (index: number) => {
        if (users[index]?.userId) {
            setEditUserId(users[index].userId);
            activateEditUserModal();
        }
    };

    useEffect(() => {
        if (
            isCA13354On_RestrictLegacyUsersOnUserManagement &&
            localStorage.getItem(Constants.StorageItems.AUTH_TYPE) ===
                Constants.AuthType.AUTH_0
        ) {
            navigate('/');
        }
    }, [navigate]);

    return (
        <>
            <UserModal
                show={createEditUserModal.isOpen}
                onHide={onHide}
                key={modalId}
                userId={editUserId}
                appDropdownOptionsForModal={myAppsForModalDropdown}
                accounts={myAccounts}
            />
            {isCA13360On_FixUserManagementTableHeading ? (
                <div className="pens-container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="my-3">
                            {t('userManagementLabels.title')}
                        </h5>
                        <button onClick={activateEditUserModal}>
                            {t('userManagementLabels.staticOptions.addUser')}
                        </button>
                    </div>
                    <UserManagementFilter
                        localFilter={localFilter}
                        setLocalFilter={setLocalFilter}
                    />
                    <DesktopTable
                        permission={false}
                        handleScroll={handleScroll}
                        tableRows={users}
                        loading={isFetching}
                        tableConstants={t('userManagementLabels', {
                            returnObjects: true,
                        })}
                        actions={{
                            editAction: {
                                editUser: editUser,
                            },
                        }}
                    />
                </div>
            ) : (
                <div className="pt-4">
                    <section className="main-content">
                        <div className="main-content-block">
                            <div className="white-content-block">
                                <Row className="filter-by">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5 className="m-0">
                                            {t(
                                                'userManagementLabels.staticOptions.filter',
                                            )}
                                            :
                                        </h5>
                                        <button onClick={activateEditUserModal}>
                                            {t(
                                                'userManagementLabels.staticOptions.addUser',
                                            )}
                                        </button>
                                    </div>
                                </Row>
                                <UserManagementFilter
                                    localFilter={localFilter}
                                    setLocalFilter={setLocalFilter}
                                />
                                <DesktopTable
                                    permission={false}
                                    handleScroll={handleScroll}
                                    tableRows={users}
                                    loading={isFetching}
                                    tableConstants={t('userManagementLabels', {
                                        returnObjects: true,
                                    })}
                                    actions={{
                                        editAction: {
                                            editUser: editUser,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </>
    );
};

export default UserManagement;
