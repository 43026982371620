import { Col, Form, Row } from 'react-bootstrap';
import AutocompleteDropdown from '../common/autocompleteDropdown/AutocompleteDropdown';
import { IHospitalFilterComponent } from '../../utils/Interface/HospitalInterface';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import ExportXLSX from '../common/ExportXLSX';
import { useTranslation } from 'react-i18next';
import { fetchAnimalsHospital } from '../../Redux/Actions/hospitalActions';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { useState } from 'react';

const { isCA12645On_ProtectedReports } = feedlotManagerFeatures;

const HospitalFilter = ({
    feedlotId,
    filterHandler,
    penFilter,
    hospitalPens,
}: IHospitalFilterComponent) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isExporting, setIsExporting] = useState(false);
    const loading = useAppSelector(state => state.hospital.loading);

    const hospitalPensSorted = hospitalPens.sort((a, b) =>
        a.name.localeCompare(b.name),
    );

    const { hospitalAnimalsList, hospitalAnimalsReports } = useAppSelector(
        state => state.hospital,
    );

    const onClickExportExcel = () => {
        setIsExporting(true);
        if (feedlotId) {
            dispatch(fetchAnimalsHospital(feedlotId, penFilter));
        }
        setIsExporting(false);
    };

    return (
        <Row className="px-4 justify-content-between">
            <Col className="d-flex">
                <Form.Group>
                    <Form.Label>
                        {t('hospital:listFilter.hospitalPen')}
                    </Form.Label>
                    <AutocompleteDropdown
                        nameKey="name"
                        valueKey="penId"
                        options={hospitalPensSorted}
                        onChange={filterHandler}
                        placeholder={t('hospital:listFilter.selectPen')}
                        selected={penFilter}
                        inputName="penId"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="mt-5 mx-2">
                        {t('hospital:listFilter.headCount') +
                            hospitalAnimalsList.length.toLocaleString()}
                    </Form.Label>
                </Form.Group>
            </Col>
            <Col className="d-flex justify-content-end align-items-end">
                <ExportXLSX
                    onClick={onClickExportExcel}
                    csvData={{
                        [t('hospital:sheetName')]: hospitalAnimalsReports,
                    }}
                    fileName={t('hospital:excelName')}
                    buttonLabel={'Export to Excel'}
                    showSpinner={isCA12645On_ProtectedReports && isExporting}
                    disabled={isCA12645On_ProtectedReports && loading}
                />
            </Col>
        </Row>
    );
};

export default HospitalFilter;
