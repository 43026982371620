import {
    currentDate,
    formatArrayForDropDown,
    formatDate,
    sortAlphaNumeric,
} from '../../helpers';
import { AppDispatch, AppThunkDispatch } from '../Store';
import { apiCall_v2, genericToastError } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import {
    ICsvData,
    IGenericSetState,
} from './../../utils/Interface/CommonInterface';
import { EditTreatmentsConstants } from '../../utils/constants/TreatmentsConstants';
import {
    IChargeoutItemsCollection,
    IGiveRecord,
    IMedicalCharge,
    IMedicalItem,
    IPen,
    ICreateTreatmentPayload,
    IUpdateTreatmentPayload,
    ISexResponse,
    ITreatmentMedicalItem,
    ITreatmentsResponse,
    ITreatmentsResponseHandlerFn,
    ITreatmentsFilter,
    IEditTreatmentsDetails,
    ITreatmentHistoryDetail,
} from '../../utils/Interface/TreatmentsInterface';
import { getToastContent } from '../../utils/ToastContent';
import { treatmentActions } from '../Reducer/treatmentsSlice';
import { NavigateFunction } from 'react-router-dom';
import {
    calculateDose,
    calculateTotalPrice,
} from '../../components/healthTreatment/TreatmentHook';
import { t } from 'i18next';
import dayjs from '../../utils/dayjs';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { LotState } from '../../utils/Interface/LotInterface';
import { setErrorModal, toastMessage } from '../Reducer/common';
import { IHandleShowBackdatedInfoModalAndNavigate } from '../../components/common/useBackdatedInfoModal';
import {
    findErrorMessage,
    hasDetailedErrorInfo,
} from '../../utils/ErrorHelper';

const {
    isCA7794On_TreatmentHistoricalGiveRecords,
    isCA9148On_GetInactiveItems,
    isCA8055On_CancelNavigation,
    isCA11386On_EIDOnExistingReports,
    isCA12516On_ShowCurrentAndMoveToPensRations,
    isCA13266On_InfoModalOnBackdatedRecords,
    isCA9204On_BetterErrors_GeneratedInvoicePeriod,
} = feedlotManagerFeatures;

interface ICattleTreatment {
    date: string;
    lotId: number;
    lotName?: string;
    animalId: number;
    animalTag?: string;
    eid?: string;
    homePenId?: number;
    homePen?: IPen;
    movePenId?: number;
    movePen?: IMovePen;
    currentPenId?: number;
    currentPen?: IPen;
    diagnosisId: number;
    treatmentProtocolId: number;
    riderId?: number;
    ownerName: string;
    severity: number;
    lotAverageCurrentWeight: number;
    currentWeight: number;
    cleanDate: string;
    temperature: string;
    techId: number;
    sexName: string;
    comments: string;
    medicalItems: IMedicalItem[];
    medicalCharges: IMedicalCharge[];
}

interface IMovePen {
    name: string;
}

interface ITreatmentProtocol {
    medicalItems?: ITreatmentMedicalItem[];
}

export const fetchLots =
    (feedlotId: number) => async (dispatch: AppDispatch) => {
        const lotsResponse = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.LOTS}?filter=State eq ${LotState.Open}&include=LotAverageWeight,Owner,Pens,${isCA12516On_ShowCurrentAndMoveToPensRations ? 'rationdetail' : ''}`,
        });

        const lotsApiFields = EditTreatmentsConstants.dropDownName.lots;
        const formatLotFields = formatArrayForDropDown(
            lotsResponse,
            lotsApiFields.lotId,
            lotsApiFields.name,
        );

        dispatch(treatmentActions.setLots(formatLotFields ?? []));
    };

export const fetchDiagnosis =
    (feedlotId: number) => async (dispatch: AppDispatch) => {
        const diagnosisResponse = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.DIAGNOSIS}`,
        });

        const diagnosisApiFields =
            EditTreatmentsConstants.dropDownName.diagnosis;
        const formatDiagnosisField = formatArrayForDropDown(
            diagnosisResponse,
            diagnosisApiFields.diagnosisId,
            diagnosisApiFields.name,
        );

        dispatch(
            treatmentActions.setTreatmentsDiagnosis([
                ...(formatDiagnosisField ?? []),
            ]),
        );
    };

export const fetchTreatmentProtocols =
    (feedlotId: number, diagnosisId: string | number | undefined) =>
    async (dispatch: AppDispatch) => {
        dispatch(treatmentActions.setProtocolsAreLoading(true));

        const treatmentProtocolsResponse = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TREATMENT_PROTOCOL}?filter=DiagnosisId eq ${diagnosisId}&limit=0`,
        });

        const treatmentProtocolsApiFields =
            EditTreatmentsConstants.dropDownName.treatmentProtocols;

        const formatProtocolField = formatArrayForDropDown(
            treatmentProtocolsResponse,
            treatmentProtocolsApiFields.treatmentProtocolId,
            treatmentProtocolsApiFields.name,
        );

        dispatch(
            treatmentActions.setTreatmentsProtocols([
                ...(formatProtocolField ?? []),
            ]),
        );
        dispatch(treatmentActions.setProtocolsAreLoading(false));
    };

export const fetchGiveMedicalItems =
    (feedlotId: number, treatmentProtocolId: number) =>
    async (dispatch: AppDispatch) => {
        if (treatmentProtocolId) {
            const treatmentProtocol: ITreatmentProtocol = await apiCall_v2({
                method: 'get',
                url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TREATMENT_PROTOCOL}/${treatmentProtocolId}?include=MedicalItem,MedicalLot`,
            });

            const giveTreatmentProtocolRecords: IGiveRecord[] = [];

            treatmentProtocol.medicalItems?.forEach(item => {
                giveTreatmentProtocolRecords.push({
                    id: item.medicalItemId,
                    name: item.name ?? '',
                });
            });
            dispatch(
                treatmentActions.setTreatmentProtocolGiveRecords(
                    giveTreatmentProtocolRecords,
                ),
            );
        }
    };

export const fetchTreatmentHistoryDetail =
    (
        feedlotId: number,
        animalIds: number[],
        setTreatmentHistory: IGenericSetState<ITreatmentHistoryDetail[]>,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(treatmentActions.setTreatmentHistoryDetailLoading(true));
        const animalFilter = `AnimalId in (${animalIds.join(',')})`;

        const treatmentsResponse: ITreatmentHistoryDetail[] = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.CATTLE_TREATMENT}${Constants.apiUrls.ODATA}?include=Animal.CattleTreatments,Diagnosis.TreatmentProtocols,CleanDate,LotAverageWeight,Rider,Lot.Owner,Tech,MedicalCharges,AverageDaysOnFeed&$filter=${animalFilter}`,
        });

        if (treatmentsResponse && treatmentsResponse.length) {
            const itemsFilter = new Set(
                treatmentsResponse.flatMap(treatment =>
                    treatment.medicalCharges.map(
                        charge => charge.medicalItemId,
                    ),
                ),
            );

            const filterString = `MedicalItemId in (${Array.from(itemsFilter)?.join(',')})`;

            if (itemsFilter) {
                const medicalItemVersions: IMedicalItem[] = await apiCall_v2({
                    method: 'get',
                    url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.MEDICAL_ITEM}${Constants.apiUrls.ODATA}?filter=${filterString}`,
                });

                treatmentsResponse.forEach(treatment => {
                    treatment.medicalCharges.forEach(charge => {
                        const version = medicalItemVersions.find(
                            item => item.medicalItemId === charge.medicalItemId,
                        );
                        if (version && charge.actualDose === null) {
                            charge.actualDose = calculateDose(
                                { ...version, dosageRate: charge?.dosageRate },
                                treatment.currentWeight,
                            );
                        }
                        charge.dosageType = version?.dosageType ?? '';
                    });
                });
            }

            setTreatmentHistory(treatmentsResponse);
        }
        dispatch(treatmentActions.setTreatmentHistoryDetailLoading(false));
    };

export const fetchAnimalIds =
    (feedlotId: number, lotId: number) => async (dispatch: AppDispatch) => {
        dispatch(treatmentActions.setTreatmentsLoading(true));
        const animalsResponse = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.ANIMAL}${Constants.apiUrls.ODATA}?filter=LotId eq ${lotId}&include=CurrentPen`,
        });

        sortAlphaNumeric(animalsResponse, 'animalTag');

        dispatch(treatmentActions.setAnimalIds(animalsResponse ?? []));
        dispatch(treatmentActions.setTreatmentsLoading(false));
    };

export const getSingleAnimal =
    (feedlotId: number, selectedAnimalId: number, isUpdate = false) =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(treatmentActions.setTreatmentsLoading(true));
        const animal = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.ANIMAL}/${selectedAnimalId}?include=CurrentPen,Sex,CattleTreatments.MedicalItems,CattleTreatments.TreatmentProtocol`,
        });

        if (animal) {
            animal.sex = animal.sex?.name;
            if (isUpdate) {
                //@ts-expect-error - this error showed up with tsconfig changes. Leaving as-is to minimize non-feature flagged changes.
                dispatch(treatmentActions.setAnimalIds([animal] ?? []));

                if (animal.eid) {
                    dispatch(
                        treatmentActions.setEditTreatmentsDetailsEid(
                            animal.eid,
                        ),
                    );
                }
            }
            const animalTreatmentHistory = animal?.cattleTreatments?.map(
                treatment => ({
                    ...treatment,
                    name: treatment.treatmentProtocol?.name,
                }),
            );
            dispatch(treatmentActions.setHistory(animalTreatmentHistory ?? []));
        }
        dispatch(treatmentActions.setTreatmentsLoading(false));
        return animal;
    };

export const fetchTechs =
    (feedlotId: number, selectedTechId?: number) =>
    async (dispatch: AppDispatch) => {
        let filter = `filter= active eq true`;
        if (selectedTechId) {
            filter += ` or techId eq ${selectedTechId}`;
        }
        const techsResponse = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TECH}/odata?${filter}`,
        });

        const techsApiFields = EditTreatmentsConstants.dropDownName.techs;
        const formatTechField = formatArrayForDropDown(
            techsResponse,
            techsApiFields.techId,
            techsApiFields.name,
        );

        dispatch(treatmentActions.setTechs([...(formatTechField ?? [])]));
    };

export const fetchRiders =
    (feedlotId: number, selectedRiderId?: number) =>
    async (dispatch: AppDispatch) => {
        let filter = `filter= active eq true`;
        if (selectedRiderId) {
            filter += ` or riderId eq ${selectedRiderId}`;
        }
        const ridersResponse = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.RIDER}/odata?${filter}`,
        });

        const ridersApiFields = EditTreatmentsConstants.dropDownName.riders;
        const formatRiderField = formatArrayForDropDown(
            ridersResponse,
            ridersApiFields.riderId,
            ridersApiFields.name,
        );

        dispatch(treatmentActions.setRiders([...(formatRiderField ?? [])]));
    };

export const fetchSex = () => async (dispatch: AppDispatch) => {
    const sexResponse: ISexResponse[] = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.SEX}`,
    });

    const sexApiFields = EditTreatmentsConstants.dropDownName.sex;

    const formatSexField = formatArrayForDropDown(
        sexResponse,
        sexApiFields.sexId,
        sexApiFields.name,
    );

    dispatch(treatmentActions.setSex([...(formatSexField ?? [])]));
};

export const fetchHospitalPens =
    (feedlotId: number) => async (dispatch: AppDispatch) => {
        const hospitalPens: IPen[] = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.PENS}/odata?filter=Kind eq 'Hospital'${isCA12516On_ShowCurrentAndMoveToPensRations ? '&isRationDetailsRequired=true' : ''}`,
        });

        dispatch(treatmentActions.setHospitalPens(hospitalPens ?? []));
    };

const getFetchMedicalItemsQuery = (selectedDate, medicalItemIds) => {
    let filterQuery = `?$filter=(MedicalItemSku/any(sku:sku/IsActive eq true) and (Category eq 'Both' or Category eq 'Treatment'))`;
    if (medicalItemIds.length) {
        filterQuery += ` or (medicalItemId in (${medicalItemIds.join(',')}))`;
    }
    if (selectedDate) {
        filterQuery += `&selectedDate=${selectedDate}`;
    }
    return filterQuery;
};

export const fetchMedicalItems =
    (
        feedlotId: number,
        selectedDate: string = formatDate(dayjs(), 'YYYY-MM-DD'),
        medicalItemIds: number[] = [],
    ) =>
    async (dispatch: AppThunkDispatch) => {
        let medicalItemsResponse;
        if (isCA9148On_GetInactiveItems) {
            const queryConditions = getFetchMedicalItemsQuery(
                selectedDate,
                medicalItemIds,
            );
            medicalItemsResponse = await apiCall_v2({
                method: 'get',
                url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.MEDICAL_ITEM}${Constants.apiUrls.ODATA}${queryConditions}`,
            });
        } else {
            let urlParameters =
                '?filter=Category eq 0 || Category eq 1&limit=0';
            if (selectedDate) {
                urlParameters += `&selectedDate=${selectedDate}`;
            }
            medicalItemsResponse = await apiCall_v2({
                method: 'get',
                url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.MEDICAL_ITEM}${Constants.apiUrls.VERSIONS}${urlParameters}`,
            });
        }

        const medicalItemsApiFields =
            EditTreatmentsConstants.dropDownName.medicalItems;
        const formatMedicalItemsField: IMedicalItem[] =
            medicalItemsResponse.length
                ? formatArrayForDropDown(
                      medicalItemsResponse,
                      medicalItemsApiFields.medicalItemId,
                      medicalItemsApiFields.name,
                  )
                : [];

        dispatch(treatmentActions.setMedicalItems(formatMedicalItemsField));

        return formatMedicalItemsField;
    };

export const fetchLotPens =
    (feedlotId: number, lotId: number) => async (dispatch: AppDispatch) => {
        const pens = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.LOTS}/${lotId}${Constants.apiUrls.PENS}`,
        });

        if (pens.length) {
            const lotPens = pens?.map(pen => ({
                penId: pen.penId,
                name: pen.pen,
                averageWeight: pen.currentWeight ?? 0,
                kind: pen.kind,
            }));
            dispatch(treatmentActions.setLotPens(lotPens));
        }
    };

export const appendProtocolMedicalItems =
    (
        feedlotId: number,
        chargeoutItemsCollection: IChargeoutItemsCollection[] = [],
        treatmentProtocolId: number,
        medicalItems: IMedicalItem[],
        weight: number,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(treatmentActions.setTreatmentsLoading(true));

        if (treatmentProtocolId) {
            const treatmentProtocol: ITreatmentProtocol = await apiCall_v2({
                method: 'get',
                url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TREATMENT_PROTOCOL}/${treatmentProtocolId}?include=MedicalItem`,
            });

            treatmentProtocol.medicalItems?.forEach(item => {
                const medicalItem = medicalItems.find(
                    medicalItem =>
                        medicalItem.medicalItemId === item.medicalItemId,
                );
                const medicalLot = medicalItem?.medicalLot?.length
                    ? medicalItem.medicalLot[0]
                    : null;

                let dose = 0;
                let totalPrice = 0;
                if (medicalItem) {
                    dose = calculateDose(medicalItem, weight);
                    totalPrice = calculateTotalPrice(medicalItem, dose);
                }

                chargeoutItemsCollection.push({
                    chargeId: 0,
                    medicalItemId: item.medicalItemId,
                    medicalLotId: medicalLot?.medicalLotId || 0,
                    dose,
                    lotAmount: medicalLot?.lotAmount || 0,
                    totalPrice,
                });
            });
            dispatch(
                treatmentActions.setChargeoutItemsCollection(
                    chargeoutItemsCollection,
                ),
            );
            const giveTreatmentProtocolRecords =
                treatmentProtocol.medicalItems?.map(item => {
                    return {
                        id: item.medicalItemId,
                        name: item.name ?? '',
                    };
                });
            dispatch(
                treatmentActions.setTreatmentProtocolGiveRecords(
                    giveTreatmentProtocolRecords,
                ),
            );
        }

        dispatch(treatmentActions.setTreatmentsLoading(false));
    };

const generateFilterQuery = (filter: ITreatmentsFilter) => {
    const filterFields = [
        { field: 'Animal/AnimalTag', value: filter?.animalTag },
        { field: 'Lot/Name', value: filter?.lotName },
        { field: 'Diagnosis/Name', value: filter?.diagnosisName },
        {
            field: 'TreatmentProtocol/Name',
            value: filter?.treatmentProtocolName,
        },
        { field: 'Rider/Name', value: filter?.riderName },
        { field: 'Lot/Owner/Name', value: filter?.ownerName },
    ];

    filterFields.push({ field: 'Animal/Eid', value: filter?.eid });

    let filterQueryString = '';

    filterQueryString += `Lot/State ne '${LotState.Inactive}' and `;

    filterFields.forEach(({ field, value }) => {
        filterQueryString += `contains(${field}, '${value.trim()}') and `;
    });

    if (filter?.treatmentsFilterFromDate) {
        filterQueryString += `date ge ${dayjs(
            filter.treatmentsFilterFromDate,
        ).format('YYYY-MM-DD')} and `;
    }

    if (filter?.treatmentsFilterToDate) {
        filterQueryString += `date le ${dayjs(
            filter.treatmentsFilterToDate,
        ).format('YYYY-MM-DD')} and `;
    }

    return filterQueryString.slice(0, -5);
};

export const fetchTreatmentsService =
    (
        feedlotId: number,
        treatmentsResponseHandler: ITreatmentsResponseHandlerFn,
        payload: string,
        filter: ITreatmentsFilter,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(treatmentActions.setTreatmentsLoading(true));
        const filterQuery = generateFilterQuery(filter);

        const treatmentsResponse: ITreatmentsResponse[] = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.CATTLE_TREATMENT}${Constants.apiUrls.ODATA}?include=Lot,Animal,Rider,Diagnosis,TreatmentProtocol,Lot.Owner,AverageDaysOnFeed&${payload}&$filter=${filterQuery}`,
        });

        treatmentsResponseHandler(treatmentsResponse);
        dispatch(treatmentActions.setTreatmentsLoading(false));
    };

export const formatTreatments = response => {
    return response.map(
        treatment =>
            (isCA11386On_EIDOnExistingReports
                ? {
                      date: dayjs(treatment?.date).toDate() || '',
                      lotName: treatment?.lotName || '',
                      animalEid: treatment?.animalEid || '',
                      animalTag: treatment?.animalTag || '',
                      homePen: treatment?.homePen?.name || '',
                      currentPen: treatment?.currentPen?.name || '',
                      movePen: treatment?.movePen?.name || '',
                      sexName: treatment?.sexName || '',
                      ownerName: treatment?.ownerName || '',
                      diagnosisName: treatment?.diagnosisName || '',
                      severity: treatment?.severity || '',
                      lotAverageCurrentWeight:
                          +treatment?.lotAverageCurrentWeight || '',
                      currentWeight: +treatment?.currentWeight || '',
                      temperature: +treatment?.temperature || '',
                      treatmentProtocolName:
                          treatment?.treatmentProtocolName || '',
                      techName: treatment?.techName || '',
                      riderName: treatment?.riderName || '',
                      comments: treatment?.comments || '',
                  }
                : {
                      date: dayjs(treatment?.date).toDate() || '',
                      lotName: treatment?.lotName || '',
                      animalTag: treatment?.animalTag || '',
                      homePen: treatment?.homePen?.name || '',
                      currentPen: treatment?.currentPen?.name || '',
                      movePen: treatment?.movePen?.name || '',
                      sexName: treatment?.sexName || '',
                      ownerName: treatment?.ownerName || '',
                      diagnosisName: treatment?.diagnosisName || '',
                      severity: treatment?.severity || '',
                      lotAverageCurrentWeight:
                          +treatment?.lotAverageCurrentWeight || '',
                      currentWeight: +treatment?.currentWeight || '',
                      temperature: +treatment?.temperature || '',
                      treatmentProtocolName:
                          treatment?.treatmentProtocolName || '',
                      techName: treatment?.techName || '',
                      riderName: treatment?.riderName || '',
                      comments: treatment?.comments || '',
                  }) as ICsvData,
    );
};

export const fetchCompleteTreatmentsService =
    (
        feedlotId: number,
        setLoading: IGenericSetState<boolean>,
        filter: string,
        setShowModal: IGenericSetState<boolean>,
    ) =>
    async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.CATTLE_TREATMENT}${Constants.apiUrls.ODATA}?include=Lot,Animal,Diagnosis,TreatmentProtocol,Rider,Lot.Owner,Tech,Animal.Sex,LotAverageWeight,HomePen,MovePen,CurrentPen&$filter=${filter}`,
            payload: {},
            isResRequired: true,
        });

        if (response && response.status) {
            if (response.status >= 200 && response.status < 300) {
                if (response.data.length) {
                    const treatments: ICsvData[] = formatTreatments(
                        response.data,
                    );

                    dispatch(
                        treatmentActions.setCompleteTreatments([
                            ...Object.values(
                                isCA11386On_EIDOnExistingReports
                                    ? t('treatments:treatmentDetailsCA11386', {
                                          returnObjects: true,
                                      })
                                    : t('treatments:treatmentDetails', {
                                          returnObjects: true,
                                      }),
                            ),
                            ...treatments,
                        ]),
                    );
                } else {
                    dispatch(
                        toastMessage(
                            getToastContent(
                                response.status,
                                '',
                                Constants.message.commonLog.NO_RECORDS_FOUND,
                            ),
                        ),
                    );
                    setLoading(false);
                }
            } else {
                dispatch(toastMessage(getToastContent(response.status)));
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
        setShowModal(false);
    };

export const updateTreatment =
    (
        payload: IUpdateTreatmentPayload,
        feedlotId: number,
        cattleTreatmentId: string,
        navigate: NavigateFunction, //delete param navigate with isCA13266On_InfoModalOnBackdatedRecords
        originalDate: string,
        updatedDate: string,
        handleShowBackdatedInfoModalAndNavigate: IHandleShowBackdatedInfoModalAndNavigate,
    ) =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(treatmentActions.setTreatmentsSubmitPending(true));
        const treatmentResponse = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.CATTLE_TREATMENT}/${cattleTreatmentId}`,
            payload: payload,
            isResRequired: true,
        });
        if (treatmentResponse?.status === Constants.responseCode.NO_CONTENT) {
            const message = t(
                'treatments:editTreatments.successMessage.updateTreatment',
            );
            dispatch(
                toastMessage(
                    getToastContent(treatmentResponse.status, '', message),
                ),
            );
            if (isCA13266On_InfoModalOnBackdatedRecords) {
                const earliestDate = dayjs(originalDate).isBefore(updatedDate)
                    ? originalDate
                    : updatedDate;
                handleShowBackdatedInfoModalAndNavigate('put', earliestDate);
            } else {
                if (isCA8055On_CancelNavigation) {
                    navigate(-1);
                } else {
                    navigate(Constants.Routes.TREATMENTS);
                }
            }
        } else {
            if (isCA9204On_BetterErrors_GeneratedInvoicePeriod) {
                if (hasDetailedErrorInfo(treatmentResponse)) {
                    dispatch(
                        setErrorModal({
                            showModal: true,
                            errorMessage: findErrorMessage(treatmentResponse),
                        }),
                    );
                } else {
                    dispatch(
                        toastMessage(getToastContent(treatmentResponse.status)),
                    );
                }
            } else {
                dispatch(
                    toastMessage(getToastContent(treatmentResponse.status)),
                );
            }
        }

        dispatch(treatmentActions.setTreatmentsSubmitPending(false));
    };

export const createTreatment =
    (
        payload: ICreateTreatmentPayload,
        navigate: NavigateFunction, //delete param navigate with isCA13266On_InfoModalOnBackdatedRecords
        handleShowBackdatedInfoModalAndNavigate: IHandleShowBackdatedInfoModalAndNavigate,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(treatmentActions.setTreatmentsSubmitPending(true));
        const treatmentResponse = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.CATTLE_TREATMENT}`,
            payload: payload,
            isResRequired: true,
        });

        if (
            treatmentResponse &&
            treatmentResponse?.status === Constants.responseCode.SUCCESS
        ) {
            const message = t(
                'treatments:editTreatments.successMessage.createTreatment',
            );
            dispatch(
                toastMessage(
                    getToastContent(treatmentResponse.status, '', message),
                ),
            );
            if (isCA13266On_InfoModalOnBackdatedRecords) {
                handleShowBackdatedInfoModalAndNavigate(
                    'post',
                    formatDate(payload.date),
                );
            } else {
                navigate(Constants.Routes.TREATMENTS);
            }
        } else if (
            treatmentResponse &&
            +treatmentResponse?.status === Constants.responseCode.CONFLICT
        ) {
            if (isCA9204On_BetterErrors_GeneratedInvoicePeriod) {
                if (hasDetailedErrorInfo(treatmentResponse)) {
                    dispatch(
                        setErrorModal({
                            showModal: true,
                            errorMessage: findErrorMessage(treatmentResponse),
                        }),
                    );
                } else {
                    const message = t(
                        'treatments:errorMessage.animalCreationFailed',
                    );
                    dispatch(
                        toastMessage(
                            getToastContent(
                                treatmentResponse.status,
                                '',
                                message,
                            ),
                        ),
                    );
                }
            } else {
                const message = t(
                    'treatments:errorMessage.animalCreationFailed',
                );
                dispatch(
                    toastMessage(
                        getToastContent(treatmentResponse.status, '', message),
                    ),
                );
            }
        } else {
            dispatch(
                toastMessage(getToastContent(treatmentResponse.status, '')),
            );
        }

        dispatch(treatmentActions.setTreatmentsSubmitPending(false));
    };

const populateTreatmentForm =
    (feedlotId: number, cattleTreatment: ICattleTreatment) =>
    async (dispatch: AppDispatch) => {
        const getCleanDateStatusInDays = (cleanDate: string) => {
            const currentCleanDate = formatDate(cleanDate);
            return dayjs(currentCleanDate).diff(currentDate(), 'day');
        };

        const treatmentDetails: IEditTreatmentsDetails = {
            lotId: cattleTreatment.lotId,
            lotName: cattleTreatment.lotName,
            animalId: cattleTreatment.animalId,
            animalTag: cattleTreatment.animalTag ?? '',
            eid: cattleTreatment.eid ?? '',
            sex: cattleTreatment.sexName,
            owner: cattleTreatment.ownerName,
            diagnosisId: cattleTreatment.diagnosisId,
            severityId: cattleTreatment.severity,
            lotPenAverageWeight:
                cattleTreatment.lotAverageCurrentWeight.toFixed(2),
            currentWeight: cattleTreatment.currentWeight.toFixed(2),
            useLotAverageWeight:
                cattleTreatment.currentWeight ===
                cattleTreatment.lotAverageCurrentWeight,
            temperature: cattleTreatment.temperature,
            cleanDate: cattleTreatment.cleanDate,
            cleanDateStatusInDays: getCleanDateStatusInDays(
                cattleTreatment.cleanDate,
            ),
            date: formatDate(cattleTreatment.date, 'YYYY-MM-DD'),
            treatmentProtocolId: cattleTreatment.treatmentProtocolId,
            techId: cattleTreatment.techId,
            riderId: cattleTreatment.riderId || 0,
            comments: cattleTreatment.comments,
            homePenId: cattleTreatment.homePenId || 0,
            homePen: cattleTreatment.homePen,
            currentPenId: cattleTreatment.currentPenId,
            currentPen: cattleTreatment.currentPen,
            movePenId: cattleTreatment.movePenId || 0,
        };

        dispatch(treatmentActions.setEditTreatmentsDetails(treatmentDetails));

        dispatch(
            treatmentActions.setOriginalTreatmentDetails(treatmentDetails),
        );

        if (treatmentDetails.treatmentProtocolId) {
            dispatch(
                fetchGiveMedicalItems(
                    feedlotId,
                    treatmentDetails.treatmentProtocolId,
                ),
            );
        }

        if (treatmentDetails.lotId) {
            dispatch(
                getSingleAnimal(feedlotId, cattleTreatment.animalId, true),
            );
            dispatch(treatmentActions.setGeneratedInvoiceCutoffDate(''));
        }

        if (treatmentDetails.diagnosisId) {
            dispatch(
                fetchTreatmentProtocols(
                    feedlotId,
                    treatmentDetails.diagnosisId,
                ),
            );
        }

        if (treatmentDetails.treatmentProtocolId) {
            dispatch(treatmentActions.setTreatmentProtocolGiveRecords([]));
        }
    };

const populateChargeout =
    (
        medicalCharges: IMedicalCharge[],
        medicalItems: IMedicalItem[],
        weight: number,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(treatmentActions.setChargeoutLoading(true));

        const chargeoutItemsCollection: IChargeoutItemsCollection[] =
            medicalCharges.map(medicalCharge => {
                const medicalItem = medicalItems.find(
                    item => item.medicalItemId === medicalCharge.medicalItemId,
                );
                const medicalLot = medicalItem?.medicalLot.find(
                    item => item.medicalLotId === medicalCharge.medicalLotName,
                );

                let dose = 0;
                let totalPrice = 0;
                if (medicalItem) {
                    dose = calculateDose(medicalItem, weight);
                    totalPrice = calculateTotalPrice(
                        medicalItem,
                        medicalCharge.actualDose ?? dose,
                    );
                }

                return {
                    chargeId: medicalCharge.chargeId ?? 0,
                    medicalItemId: medicalCharge.medicalItemId,
                    medicalLotId: medicalLot?.medicalLotId ?? 0,
                    dose,
                    lotAmount: medicalLot?.lotAmount ?? 0,
                    totalPrice: totalPrice,
                    actualDose: medicalCharge.actualDose,
                };
            });

        dispatch(
            treatmentActions.setChargeoutItemsCollection(
                chargeoutItemsCollection,
            ),
        );
        dispatch(treatmentActions.setChargeoutLoading(false));
    };

const populateGiveRecords =
    (feedlotId: number, cattleTreatment: ICattleTreatment) =>
    async (dispatch: AppDispatch) => {
        const giveRecords: IGiveRecord[] = [];

        const filterArray = (array1: number[], array2: number[]) => {
            const filtered = array1.filter(element => {
                return array2.indexOf(element) === -1;
            });
            return filtered;
        };

        const treatmentProtocolResponse = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TREATMENT_PROTOCOL}/${cattleTreatment.treatmentProtocolId}?include=MedicalItem,MedicalLot`,
        });

        const responseBasedMedicalItemsIds = cattleTreatment.medicalItems?.map(
            ({ medicalItemId }) => medicalItemId,
        );

        const treatmentProtocolBasedMedicalItemIds: number[] = [];

        if (Array.isArray(treatmentProtocolResponse.medicalItems)) {
            treatmentProtocolResponse.medicalItems.forEach(item => {
                treatmentProtocolBasedMedicalItemIds.push(item.medicalItemId);
            });
        }

        const deleteableMedicalItems = filterArray(
            responseBasedMedicalItemsIds,
            treatmentProtocolBasedMedicalItemIds,
        );
        deleteableMedicalItems.forEach(item => {
            const filteredDeleteableMedicalItem =
                cattleTreatment.medicalItems.find(
                    medicalItem => medicalItem.medicalItemId === item,
                );

            if (filteredDeleteableMedicalItem !== undefined) {
                giveRecords.push({
                    id: filteredDeleteableMedicalItem.medicalItemId,
                    name: filteredDeleteableMedicalItem.name,
                });
            }
        });

        dispatch(treatmentActions.setGiveRecords(giveRecords));
    };

export const fetchTreatmentDetailsService =
    (treatmentId: number, feedlotId: number) =>
    async (dispatch: AppDispatch) => {
        dispatch(treatmentActions.setTreatmentsLoading(true));

        const cattleTreatment = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.CATTLE_TREATMENT}/${treatmentId}?include=Animal.Sex,Animal.CattleTreatments,Diagnosis.TreatmentProtocols,MedicalItems,MedicalLot,CleanDate,LotAverageWeight,Rider,Lot.Owner,Tech,MedicalCharge,CurrentPen,HomePen`,
        });

        if (isCA7794On_TreatmentHistoricalGiveRecords) {
            dispatch(
                treatmentActions.setHistoricalGiveRecord(
                    cattleTreatment.medicalItems,
                ),
            );
        }

        dispatch(populateTreatmentForm(feedlotId, cattleTreatment));

        const medicalItemIds = cattleTreatment.medicalItems.map(
            medicalItem => medicalItem.medicalItemId,
        );
        dispatch(
            fetchMedicalItems(feedlotId, cattleTreatment.date, medicalItemIds),
        ).then(medicalItems => {
            dispatch(
                populateChargeout(
                    cattleTreatment.medicalCharges,
                    medicalItems,
                    cattleTreatment.currentWeight,
                ),
            );
        });

        dispatch(treatmentActions.setTreatmentsLoading(false));

        if (!isCA7794On_TreatmentHistoricalGiveRecords) {
            dispatch(populateGiveRecords(feedlotId, cattleTreatment));
        }
    };

export const deleteTreatmentRecord =
    (
        treatmentId: string,
        feedlotId: number,
        navigate: NavigateFunction, //delete param navigate with isCA13266On_InfoModalOnBackdatedRecords
        date: string,
        handleShowBackdatedInfoModalAndNavigate: IHandleShowBackdatedInfoModalAndNavigate,
    ) =>
    async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'delete',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.CATTLE_TREATMENT}/${treatmentId}`,
        });
        if (!response) {
            dispatch(
                toastMessage(
                    getToastContent(
                        Constants.responseCode.NO_CONTENT,
                        'delete',
                    ),
                ),
            );
            if (isCA13266On_InfoModalOnBackdatedRecords) {
                handleShowBackdatedInfoModalAndNavigate('delete', date);
            } else {
                navigate(-1);
            }
        } else {
            if (isCA9204On_BetterErrors_GeneratedInvoicePeriod) {
                if (hasDetailedErrorInfo(response)) {
                    dispatch(
                        setErrorModal({
                            showModal: true,
                            errorMessage: findErrorMessage(response),
                        }),
                    );
                } else {
                    genericToastError();
                }
            } else {
                genericToastError();
            }
        }
    };
