import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FinancialRoutes from '../../utils/constants/FinancialApp/FinancialRoutes';
import { Roles, privateAppRoutes } from '../../utils/constants/RoleConstants';
import { ISideNavTabButton } from '../../utils/Interface/SideNavTabInterface';
import { IPrivateRoute } from '../../utils/routes/Interface';
import {
    IGenericSetState,
    sideNavTabs,
} from '../../utils/Interface/CommonInterface';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { setRoute, setShowModal } from '../../Redux/Reducer/feedBillUpdate';
import { setSelectedSideNavTab } from '../../Redux/Reducer/common';

const SideNavTabButton = ({
    id,
    setSideNavTab,
    item,
    active,
    handleActive,
    usersRoles,
}: ISideNavTabButton) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [buyPoRoutes, setBuyPoRoutes] = useState<string[]>([]);
    const [financialRoutes, setFinancialRoutes] = useState<string[]>([]);
    const [feedlotManagerRoutes, setFeedlotManagerRoutes] = useState<string[]>(
        [],
    );
    const { lastUpdate } = useAppSelector(state => state.feedBillUpdate);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const setRoutesArray = (
            routeList: IPrivateRoute[] | undefined,
            setterFunction: IGenericSetState<string[]>,
        ) => {
            const routeArray = routeList?.map(route => route.path) ?? [];
            setterFunction(routeArray);
        };

        setRoutesArray(privateAppRoutes.buyPoRoutes.routes, setBuyPoRoutes);
        setRoutesArray(
            privateAppRoutes.financialRoutes.routes,
            setFinancialRoutes,
        );
        setRoutesArray(
            privateAppRoutes.feedlotManagerRoutes.routes,
            setFeedlotManagerRoutes,
        );
    }, []);

    useEffect(() => {
        const checkRouteExists = (routesArray: string[], pathname: string) =>
            routesArray.some((route: string) => {
                const routeStrings = route.split('/');
                if (
                    routeStrings[1] === pathname ||
                    (routeStrings[2] ? routeStrings[2] === pathname : false)
                ) {
                    return true;
                }

                return false;
            });

        const routeMatchString = location.pathname.split('/')[1];
        if (location.pathname === '/') {
            handleActive('');
            setSideNavTab('');
            dispatch(setSelectedSideNavTab(sideNavTabs.NotSelected));
        } else if (checkRouteExists(buyPoRoutes, routeMatchString)) {
            handleActive(0);
            setSideNavTab('buyPo');
            dispatch(setSelectedSideNavTab(sideNavTabs.BuyPo));
        } else if (checkRouteExists(financialRoutes, routeMatchString)) {
            handleActive(1);
            setSideNavTab('financial');
            dispatch(setSelectedSideNavTab(sideNavTabs.Financial));
        } else if (checkRouteExists(feedlotManagerRoutes, routeMatchString)) {
            handleActive(2);
            setSideNavTab('fm');
            dispatch(setSelectedSideNavTab(sideNavTabs.Fm));
        }
    }, [
        buyPoRoutes,
        financialRoutes,
        feedlotManagerRoutes,
        location.pathname,
        active,
        handleActive,
        setSideNavTab,
        dispatch,
    ]);

    const handleNavigate = () => {
        if (item.state === 'buyPo') {
            if (
                usersRoles.includes(Roles.ADMIN) ||
                usersRoles.includes(Roles.BUYER) ||
                usersRoles.includes(Roles.TENANT_ADMIN) ||
                usersRoles.includes(Roles.OFFICE_MANAGER) ||
                usersRoles.includes(Roles.FEEDLOT_MANAGER)
            ) {
                navigate('/buypos');
            } else if (usersRoles.includes(Roles.TRUCKING_COMPANY)) {
                navigate('/truckingcompany');
            }
        } else if (item.state === 'financial') {
            navigate(FinancialRoutes.App.LOTS);
        } else if (item.state === 'fm') {
            navigate('/fmdashboard');
        } else {
            navigate('/');
        }
    };

    const handleClick = () => {
        if (lastUpdate) {
            dispatch(setShowModal(true));
            handleStoreRoute();
        } else {
            handleNavigate();
        }

        setSideNavTab(item.state);
        handleActive(id);
    };

    const handleStoreRoute = () => {
        if (item.state === 'buyPo') {
            dispatch(setRoute('/buypos'));
        } else if (item.state === 'financial') {
            dispatch(setRoute(FinancialRoutes.App.LOTS));
        } else if (item.state === 'fm') {
            dispatch(setRoute('/fmdashboard'));
        } else {
            dispatch(setRoute('/'));
        }
    };

    return (
        <button
            className={`${active ? 'active' : ''}`}
            key={id}
            onClick={handleClick}
        >
            {item.label}
        </button>
    );
};

export default SideNavTabButton;
