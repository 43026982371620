import auth0, {
    Auth0DecodedHash,
    Auth0Error,
    Auth0ParseHashError,
} from 'auth0-js';
import { jwtDecode } from 'jwt-decode';
import {
    Location,
    NavigateFunction,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { Buffer } from 'buffer';
import { Roles } from '../../../../../utils/constants/RoleConstants';
import { toast } from 'react-toastify';
import {
    IAuth,
    IJwtPayload,
    ILocalAuthResponse,
} from '../../../../../utils/Interface/AuthInterface';
import { EnvRoles, TOKEN } from '../constants/AuthConstants';
import dayjs from '../../../../../utils/dayjs';
import { apiCall_v2, setBasic } from '../../../../../Services/AxiosService';
import Constants from '../../../../../utils/Constants';
import { FeedlotManagerModelsResponseModelsFeedlotApiResponse } from '../../../../../Redux/Apis/FMFeedlot/baseFMFeedlotApi';
import { AppDispatch, useAppDispatch } from '../../../../../Redux/Store';
import { rootApi } from '../../../../../Redux/Apis/rootApi';
import { feedlotManagerFeatures } from '../../../../feedlotManager/featureFlags/feedlotManagerFeatures';
import { authActions } from '../authSlice';

const { isCA10586On_refactorFeedCall, isCA9919On_ManageUsersPage } =
    feedlotManagerFeatures;

let _username = '';

export default function AuthWrapper(localStorageKey: string) {
    const navigate: NavigateFunction = useNavigate();
    const location: Location = useLocation();
    const dispatch: AppDispatch = useAppDispatch();
    return new Auth(navigate, location, dispatch, localStorageKey);
}

class Auth implements IAuth {
    navigate: NavigateFunction;
    location: Location;
    dispatch: AppDispatch;
    auth0: auth0.WebAuth;
    localStorageKey: string;

    private _accessToken: string | null = null;
    private _decodedAccessToken: IJwtPayload | null = null;
    private _expiresAt = 0;

    constructor(
        navigate: NavigateFunction,
        location: Location,
        dispatch: AppDispatch,
        localStorageKey: string,
    ) {
        this.navigate = navigate;
        this.location = location;
        this.dispatch = dispatch;
        this.auth0 = new auth0.WebAuth({
            domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
            redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            responseType: 'token id_token',
        });
        this.localStorageKey = localStorageKey;
    }

    get accessToken(): string | null {
        return this._accessToken;
    }
    set accessToken(token: string | null) {
        this._accessToken = token;
    }

    get decodedAccessToken(): IJwtPayload | null {
        return this._decodedAccessToken;
    }
    set decodedAccessToken(token: IJwtPayload | null) {
        this._decodedAccessToken = token;
    }

    get expiresAt(): number {
        return this._expiresAt;
    }
    set expiresAt(expiry: number) {
        this._expiresAt = expiry;
    }

    login = () => {
        localStorage.setItem(
            Constants.StorageItems.AUTH_TYPE,
            Constants.AuthType.AUTH_0,
        );
        localStorage.setItem(
            Constants.StorageItems.REDIRECT_ON_LOGIN,
            JSON.stringify(this.location),
        );
        this.auth0.authorize();
    };

    logout = () => {
        this.auth0.logout({
            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
            returnTo: process.env.REACT_APP_LOGOUT_REDIRECT,
        });
        localStorage.clear();
        if (isCA10586On_refactorFeedCall) {
            this.dispatch(rootApi.util.resetApiState());
        }
    };

    handleAuthentication = () => {
        this.auth0.parseHash(
            (
                err: Auth0ParseHashError | null,
                authResult: Auth0DecodedHash | null,
            ) => {
                if (authResult) {
                    this.setSession(authResult);
                    const redirectLocation =
                        localStorage.getItem(
                            Constants.StorageItems.REDIRECT_ON_LOGIN,
                        ) === 'undefined'
                            ? '/'
                            : JSON.parse(
                                  localStorage.getItem(
                                      Constants.StorageItems.REDIRECT_ON_LOGIN,
                                  ) || '',
                              );
                    this.navigate(redirectLocation);
                } else if (err) {
                    this.navigate('/');
                    toast.error(
                        `Error: ${err.error}. Check the console for further details.`,
                        {
                            position: 'top-right',
                            autoClose: 15000,
                        },
                    );

                    console.error(err);
                }
                localStorage.removeItem(
                    Constants.StorageItems.REDIRECT_ON_LOGIN,
                );
            },
        );
    };

    setSession = (authResult: Auth0DecodedHash) => {
        if (authResult.expiresIn && authResult.accessToken) {
            this._expiresAt =
                localStorage.getItem(Constants.StorageItems.AUTH_TYPE) ===
                Constants.AuthType.LOCAL
                    ? authResult.expiresIn + dayjs().valueOf()
                    : authResult.expiresIn * 1000 + dayjs().valueOf();
            this._accessToken = authResult.accessToken;
            this._decodedAccessToken = jwtDecode<IJwtPayload>(
                this._accessToken || '',
            );
            this.scheduleTokenRenewal();
        }
    };

    isAuthenticated = (excludeTransientToken = true): boolean => {
        if (excludeTransientToken) {
            return (
                !this.isTransientToken() && dayjs().valueOf() < this._expiresAt
            );
        } else {
            return dayjs().valueOf() < this._expiresAt;
        }
    };

    getAccessToken = () => {
        if (!this._accessToken) {
            throw new Error('No access token found.');
        }
        return this._accessToken;
    };

    getDecodedToken = (): IJwtPayload | null => this._decodedAccessToken;

    saveLocalToken = auth => {
        localStorage.setItem(
            this.localStorageKey,
            Buffer.from(auth).toString('base64'),
        );
    };

    localAuthLogin = async ({
        username,
        password,
        redirectPath,
        isTransient = false,
    }: {
        username: string;
        password: string;
        redirectPath: string;
        isTransient: boolean;
    }): Promise<void> => {
        localStorage.setItem(
            Constants.StorageItems.AUTH_TYPE,
            Constants.AuthType.LOCAL,
        );
        setBasic(username, password);
        _username = username;
        const res = await apiCall_v2({
            method: 'get',
            url: isTransient
                ? Constants.apiUrls.TRANSIENT_USER_AUTH
                : `${Constants.apiUrls.USER_AUTH}?account=0`,
            isResRequired: true,
            showBackendErrorToast: !isCA9919On_ManageUsersPage,
        });
        if (res?.status === Constants.responseCode.SUCCESS) {
            const authres: ILocalAuthResponse = res.data;
            this.saveLocalToken(JSON.stringify(authres));
            this.setSession({
                expiresIn:
                    dayjs(authres.tokenExpiry).valueOf() - dayjs().valueOf(),
                accessToken: authres.token,
            });
            if (redirectPath) {
                this.navigate(redirectPath);
            }
        } else if (isCA9919On_ManageUsersPage) {
            if (res?.status === Constants.responseCode.FORBIDDEN) {
                toast.error(Constants.message.commonLog.USER_INACTIVE);
            } else {
                toast.error(res?.title);
            }
        }
    };

    renewLocalToken = async () => {
        const ngatAuth = localStorage.getItem(this.localStorageKey);
        if (ngatAuth) {
            const json = Buffer.from(ngatAuth, 'base64').toString('utf-8');
            const authl: ILocalAuthResponse = JSON.parse(json);
            const tokenAboutToExpire =
                (localStorage.getItem(Constants.StorageItems.AUTH_TYPE) ===
                Constants.AuthType.LOCAL
                    ? dayjs(authl.tokenExpiry).valueOf() -
                      dayjs().valueOf() -
                      9000
                    : dayjs(authl.tokenExpiry).valueOf() - dayjs().valueOf()) >
                0;
            if (tokenAboutToExpire) {
                const token = {
                    expiresIn:
                        dayjs(authl.tokenExpiry).valueOf() - dayjs().valueOf(),
                    accessToken: authl.token,
                };
                this.setSession(token);

                return JSON.stringify(token);
            } else if (
                localStorage.getItem(Constants.StorageItems.AUTH_TYPE) ===
                Constants.AuthType.LOCAL
            ) {
                setBasic(_username, authl.refreshToken);
                const res = await apiCall_v2({
                    method: 'get',
                    url: `${Constants.apiUrls.REFRESH}`,
                    isResRequired: true,
                    showBackendErrorToast: true,
                });
                if (res?.status === Constants.responseCode.SUCCESS) {
                    const authres: ILocalAuthResponse = res.data;
                    this.setSession({
                        expiresIn:
                            dayjs(authres.tokenExpiry).valueOf() -
                            dayjs().valueOf(),
                        accessToken: authres.token,
                    });
                    return JSON.stringify(authres);
                } else {
                    this.dispatch(authActions.setLogout());
                }
            }
        }

        return null;
    };

    renewToken = async (
        cb:
            | ((
                  err: Auth0Error | null,
                  result: Auth0DecodedHash | null,
              ) => void)
            | null,
    ) => {
        const token = await this.renewLocalToken();
        if (token) {
            if (cb) cb(null, null);
        } else {
            this.auth0.checkSession(
                {},
                (err: Auth0Error | null, result: Auth0DecodedHash) => {
                    if (err) {
                        if (this.isAuthenticated()) {
                            console.error(
                                `Error: ${err.error} - ${err.error_description}.`,
                            );
                        }
                    } else {
                        this.setSession(result);
                    }
                    if (cb) cb(err, result);
                },
            );
        }
    };

    scheduleTokenRenewal = () => {
        const delay = this._expiresAt - dayjs().valueOf();
        const reducedDelayToEnsureTokenValidWhenRenewed =
            localStorage.getItem(Constants.StorageItems.AUTH_TYPE) ===
            Constants.AuthType.LOCAL
                ? delay - 900000
                : delay / 2;
        if (
            localStorage.getItem(Constants.StorageItems.AUTH_TYPE) ===
            Constants.AuthType.LOCAL
                ? reducedDelayToEnsureTokenValidWhenRenewed > 0
                : delay > 0
        )
            setTimeout(
                () => this.renewToken(null),
                reducedDelayToEnsureTokenValidWhenRenewed,
            );
    };

    getTenantId = (): string => this.getJwtPropertyValue(TOKEN.TID_FIELD);

    getUserEmail = (): string =>
        this.getJwtPropertyValue(TOKEN.EMAILADDRESS_FIELD);

    getUserName = (): string => this.getJwtPropertyValue(TOKEN.NAME_FIELD);

    getUsersRoles = (): string[] => this.getJwtPropertyValues(TOKEN.ROLE_FIELD);

    getUsersActors = (): string[] =>
        this.getJwtPropertyValues(TOKEN.ACTOR_FIELD);

    getUsersEnvs = (): string[] => this.getJwtPropertyValues(TOKEN.ENV_FIELD);

    getUsersAppSubscriptions = (): string[] =>
        this.getJwtPropertyValues(TOKEN.APP_FIELD);

    getUserId = (): string => this.getJwtPropertyValue(TOKEN.SUB_FIELD);

    getUsersFeedlots = (
        allFeedlots: Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>[],
    ): Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>[] => {
        let usersFeedlots: Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>[] =
            [];
        const usersRoles: string[] = this.getUsersRoles();
        const usersActors: string[] = this.getUsersActors();

        if (
            (usersRoles.includes(Roles.ADMIN) ||
                usersRoles.includes(Roles.OFFICE_MANAGER)) &&
            usersActors.length === 0
        ) {
            usersFeedlots = allFeedlots;
        } else {
            usersFeedlots = allFeedlots.filter(feedlot =>
                usersActors.includes(feedlot.label || ''),
            );
        }
        return usersFeedlots;
    };

    accessHandler = (designatedRoles: string[]): boolean => {
        const usersRoles: string[] = this.getUsersRoles();
        for (
            let roleIndex = 0;
            roleIndex < designatedRoles.length;
            roleIndex++
        ) {
            const canAccess = usersRoles.includes(designatedRoles[roleIndex]);

            if (canAccess) {
                return true;
            }
        }
        return false;
    };

    hasSubscriptionServiceAccess = () => {
        const paymentServiceStatus =
            process.env.REACT_APP_SUBSCRIPTIONS_SERVICE === 'on';

        return paymentServiceStatus;
    };

    hasWarehousingAppAccess = () => {
        return this.accessHandler([Roles.WAREHOUSING_APP]);
    };

    hasTenantId = (): boolean => !!this.getJwtPropertyValue(TOKEN.TID_FIELD);

    hasSubscriptions = (): boolean => {
        return this.getUsersAppSubscriptions().length > 0;
    };

    hasBetaRestriction = (): boolean =>
        this.getUsersEnvs().includes(EnvRoles.Beta);

    hasDevRestriction = (): boolean =>
        this.getUsersEnvs().includes(EnvRoles.Development);

    isTransientToken = (): boolean =>
        this.getJwtPropertyValues('scope').includes('TransientAuthentication');

    private getJwtPropertyValues = (property: string): string[] => {
        const abbrprop = () => {
            return property === TOKEN.EMAILADDRESS_FIELD
                ? TOKEN.EMAIL_FIELD
                : property.substring(property.lastIndexOf('/') + 1);
        };

        let jwtPropertyValues: string | string[] =
            this._decodedAccessToken?.[property] ??
            this._decodedAccessToken?.[abbrprop()] ??
            [];

        if (typeof jwtPropertyValues === 'string') {
            jwtPropertyValues = [jwtPropertyValues];
        }
        return jwtPropertyValues;
    };

    private getJwtPropertyValue = (property: string): string =>
        this.getJwtPropertyValues(property)?.[0] ?? '';
}
