export const ShipPOCreateConstants = {
    labels: {
        edit: 'Edit',
        addNew: 'Add New',
        createShipPo: 'Create Ship PO',
        updateShipPo: 'Update Ship PO',
        cattlePaid: 'Cattle Paid',
        freightPaid: 'Freight Paid',
        cattleReceived: 'Cattle Received',
        next: 'Next',
        delete: 'Delete',
        close: 'Save and Close',
        submit: 'Submit',
        createdBy: 'Created by',
        saved: 'Saved',
        submittedBy: 'Submitted by',
        modifiedBy: 'Last Modified by',
    },
    form: {
        invoiceNumber: {
            label: 'Invoice Number',
            maxLength: 30,
        },
        cattleAR: {
            label: 'Cattle AR',
            name: 'isCattleAR',
        },
        freightPaid: {
            label: 'Freight Paid',
            name: 'isFreightPaid',
        },
    },
    moduleType: 'SHIP_PO',
    missingFieldMessage: 'Please Add ',
    headCountAndGrossWeightErrorMessage:
        'Please fill in either Head Count and Gross Weight or Estimated Head Count and Estimated Gross Weight',
    headCountAndEstimatedHeadCountErrorMessage:
        'Both Head and Estimated Head have been selected. Please remove one or the other.',
    grossWeightAndEstimatedGrossWeightErrorMessage:
        'Both Gross Weight and Estimated Gross Weight have been selected. Please remove one or the other.',
    errorModalConstants: {
        destination: 'Destination',
        location: 'Ship Location',
        pickupDate: 'Pickup Date',
        truckingCompany: 'Trucking Company',
        lotError: 'Lot',
        estimatedGrossWeightPerHdError: 'Est GW/Hd',
        estimatedHeadCountError: 'Est Head Count',
        grossWeightError: 'Gross Weight',
        headCountError: 'Head Count',
        ownerError: 'Owner',
    },
    attachmentFileLengthErrorMessage: 'You can only select up to ',
    attachmentFileSizeErrorMessage: 'Each file must be no larger than ',
    duplicateFileErrorMessage:
        'A file with the same name already exists. Do you want to replace it?  ',
};

// ShipPO Fees Constants
export const ShipPOFeesConstants = {
    labels: {
        step3: 'Step 3',
        additionalFees: 'Additional Fees',
        newFee: 'New Fee',
        add: 'Add',
        total: {
            additionalFeesTotal: 'Additional Fees Total',
            grandTotal: 'Grand Total',
        },
    },
    form: {
        feeType: {
            label: 'Additional Fee Type',
            mobileLabel: 'Fee Type',
            name: 'feeType',
        },
        amount: {
            label: 'Amount',
            name: 'feeAmount',
            maxLength: 10,
        },
        notes: {
            label: 'Notes',
            name: 'notes',
        },
    },
    initialState: {
        fee: { feeId: 0, feeType: '', feeAmount: '', notes: '' },
    },
};

// ShipPO Global Info Constants
export const ShipPOGlobalInfoConstants = {
    title: 'Global Information',
    totalNOFiles: 10,
    globalInfoModalFields: [
        'location',
        'destination',
        'truckingCompany',
        'shipPoPayee',
    ],

    labels: {
        step1: 'Step 1',
    },
    form: {
        shipPO: {
            label: 'Ship PO',
            mobileLabel: 'Ship PO',
            placeholder: '(auto-generate)',
        },
        pickupDate: {
            label: 'Pickup Date',
            mobileLabel: 'Pickup Date',
            fieldName: 'pickupDate',
        },
        location: {
            label: 'Ship Location',
            mobileLabel: 'Ship Location',
            placeholder: 'Select Location',
            fieldName: 'location',
            isRequired: true,
            activateModalConstant: {
                heading: 'Activate Location',
                text: ' is currently Inactive. Activate and use this location.',
            },
        },
        destination: {
            label: 'Destination',
            mobileLabel: 'Destination',
            placeholder: 'Select Destination',
            fieldName: 'destination',
            isRequired: true,
            activateModalConstant: {
                heading: 'Activate Destination',
                text: ' is currently Inactive. Activate and use this destination.',
            },
        },
        truckingCompany: {
            label: 'Trucking Company',
            mobileLabel: 'Trucking Company',
            placeholder: 'Select Company',
            fieldName: 'truckingCompany',
            idField: 'companyId',
            invalid: '',
            isRequired: true,
            activateModalConstant: {
                heading: 'Activate Trucking Company',
                text: ' is currently Inactive. Activate and use this Trucking Company.',
            },
        },
        brandSlip: {
            label: 'Brand Slip#',
            invalid: '',
            mobileLabel: 'Brand Slip',
            fieldName: 'brandSlip',
        },
        attachment: {
            label: 'Attach Document',
            invalid: '',
            button: 'Choose files',
        },
        payee: {
            headingLabel: 'Create New Payee',
            label: 'Payee',
            mobileLabel: 'Payee',
            fieldName: 'shipPoPayee',
            idField: 'id',
            placeholder: 'Select Payee',
            invalid: '',
        },
    },
    dynamicFields: {
        payeeName: {
            key: 'name',
            label: 'Payee Name',
            placeholder: 'Enter Payee Name',
            valueType: 'string',
            maxLength: 50,
        },
    },
    cancel: 'Cancel',
    create: 'Create',
    update: 'Update',
    initialState: {
        payeeDetail: {
            name: '',
        },
        payeeError: {
            name: false,
        },
    },
};

// ShipPO List Constants
export const ShipPOListConstants = {
    title: 'Ship POs',
    filterBy: 'Filter By',
    limit: 20,
    offset: 0,
    apply: 'apply',
    lbsValue: 'lbs',
    submit: {
        success: 'Successfully Submitted!',
    },
    selectedPickupDateRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    },
    confirmationMessage: {
        deleteSingle: 'Do you want to delete this ShipPO : ',
        deleteMultiple: 'Do you want to delete selected ShipPOs : ',
    },
    labels: {
        searchAndFilters: 'Search and Filters',
        shipPO: 'Ship PO',
        addShipPO: 'Add Ship PO',
        schedulePickUps: 'Scheduled Pick Ups',
        showCompleted: 'Show completed',
        noDataFound: 'No data found',
        freightPaid: 'Freight Paid',
        cattleAR: 'cattle AR',
    },
    filterInitialState: {
        shipPOId: '',
        pickupFromDate: '',
        pickupToDate: '',
        location: '',
        hdCount: '',
        estimatedHeadCount: '',
        destination: '',
        truckingCompany: '',
        cattleAR: '',
        isFreightPaid: '',
        limit: 20,
        offset: 0,
        show: 'incomplete',
    },
    resetFilterState: {
        shipPOId: '',
        pickupFromDate: '',
        pickupToDate: '',
        location: '',
        hdCount: '',
        estimatedHeadCount: '',
        destination: '',
        truckingCompany: '',
        payee: '',
        cattleAR: '',
        isFreightPaid: '',
        limit: 20,
        offset: 0,
        show: 'incomplete',
    },
    filter: {
        shipPO: {
            label: 'Ship PO',
            name: 'shipPOId',
            maxLength: 9,
        },
        pickupDate: {
            label: 'Pickup Date',
        },
        location: {
            label: 'Ship Location',
            name: 'location',
            maxLength: 50,
        },
        headCount: {
            label: 'Hd Count Total',
            name: 'hdCount',
            maxLength: 9,
        },
        estimatedHeadCount: {
            label: 'Est Hd Count Total',
            name: 'estimatedHeadCount',
            maxLength: 9,
        },
        destination: {
            label: 'Destination',
            name: 'destination',
            maxLength: 50,
        },
        truckingCompany: {
            label: 'Trucking Company',
            name: 'truckingCompany',
            maxLength: 50,
        },
        payee: {
            label: 'Payee',
            name: 'payee',
            maxLength: 50,
        },
        clearFilter: {
            label: 'Clear Filters',
        },
        showStatus: {
            complete: 'complete',
            inComplete: 'incomplete',
        },
        showComplete: {
            label: 'Completed',
            name: 'show',
            value: 'complete',
        },
        showAll: {
            label: 'All',
            name: 'show',
            value: 'all',
        },
        showInComplete: {
            label: 'Incomplete',
            name: 'show',
            value: 'incomplete',
        },
        active: {
            label: 'active',
        },
    },
    headers: {
        shipPO: {
            label: 'Ship PO',
        },
        shipDate: {
            label: 'Ship Date',
        },
        location: {
            label: 'Ship Location',
        },
        headCount: {
            label: 'Hd Ct',
        },
        estimatedHeadCount: {
            label: 'Est Hd',
        },
        destination: {
            label: 'Destination',
        },
        truckingCompany: {
            label: 'Trucking Company',
        },
        payee: {
            label: 'Payee',
        },
        cattleAR: {
            label: 'Cattle AR',
        },
        freightPaid: {
            label: 'Freight Paid',
        },
    },
};

// ShipPO Shipments Constants
export const ShipPOShipmentsConstants = {
    title: 'Shipments',
    step2: 'Step 2',
    edit: 'Edit',
    labels: {
        order: 'Shipment',
        totalHeaders: {
            total: 'Totals',
            totalHeadCount: 'Total Hd Ct',
            totalEstimatedHdCount: 'Total Estimated Hd Ct',
            totalGrossWeight: 'Total Gross Weight',
            totalEstimatedGrossWeightPerHd:
                'Total Estimated Gross Weight Per Hd',
            totalPayWeight: 'Total Pay Weight',
            totalDollars: 'Total Dollars',
            notes: 'Notes',
        },
        mobileLength: 'mobileLength',
        mobile: 'mobile',
        desktop: 'desktop',
    },
    form: {
        id: {
            name: 'id',
        },
        lot: {
            label: 'Lot #',
            name: 'lot',
            maxLength: 15,
        },
        broker: {
            label: 'Broker',
            name: 'broker',
            maxLength: 15,
        },
        headCount: {
            label: 'Hd',
            name: 'headCount',
            maxLength: 9,
        },
        estimatedHeadCount: {
            label: 'Est Hd',
            name: 'estimatedHeadCount',
            maxLength: 9,
        },
        grossWeight: {
            label: 'Gross Wt',
            name: 'grossWeight',
            maxLength: 10,
        },
        estimatedGrossWeight: {
            label: 'Est GW',
            name: 'estimatedGrossWeight',
            maxLength: 10,
        },
        shrink: {
            label: 'Shrink %',
            invalid: '',
            name: 'shrink',
            maxLength: 5,
        },
        payWeight: {
            label: 'Pay Wt',
            invalid: '',
            name: 'payWeight',
            maxLength: 10,
        },
        totalDollars: {
            label: '$ Amount',
            invalid: '',
            name: 'totalDollars',
            maxLength: 10,
        },
        owner: {
            label: 'Owner',
            invalid: '',
            name: 'owner',
            placeholder: 'Select Owner',
        },
    },
    totals: 'Totals',
    avgGrossWeight: 'Avg Gross Weight Per Head',
};

export const ShipPOShipmentNotesConstants = {
    title: 'Notes',
    shipmentNotes: {
        name: 'shipmentNotes',
        placeholder: 'Enter Notes',
        maxLength: 100,
    },
};

export const ShipPOPaymentInfo = {
    form: {
        invoiceNumber: {
            label: 'Invoice Number',
            maxlength: 30,
        },
        cattleAR: {
            label: 'Cattle AR',
        },
        freightPaid: {
            label: 'Freight Paid',
        },
    },
};

export const ShipPOExcelConstants = {
    label: 'Export to Excel',
    fileName: 'Shippo Record History',
};
